<template>
  <div class="export">
    <b-button
      size="is-small"
      @click="exportAsCsv()"
    >
      Export
      <b-icon
        icon="file-csv"
        size="is-small"
        class="ml-1"
      />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'Export',
  props: {
    gridApi: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    exportAsCsv() {
      if (this.gridApi) {
        this.gridApi.exportDataAsCsv({
          allColumns: true,
          skipRowGroups: true,
          fileName: 'month-to-date.csv',
        });
      }
    },
  },
};
</script>

<style scoped>
  .export {
    display: inline-block;
  }
</style>
